<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/products-stock" class="sidebar__link">
      <img src="@/assets/img/exam-logo.png" alt="" />
      <p>{{ $t('Sidebar.products') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/axo-history" class="sidebar__link">
      <img src="@/assets/img/archive.png" alt="" />
      <p>{{ $t('Sidebar.history') }}</p>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
