<template>
  <div
    class="w-[80%] scheduleCollapse bg-white h-screen shadow-2xl fixed top-0 z-[1234233] flex items-center transition-all"
    style="transition: all 0.4s"
    :class="{
      '-right-[80%]': !isOpen,
      'right-0': isOpen,
    }"
  >
    <div class="relative h-full left-0 top-0">
      <button
        @click="isOpen = !isOpen"
        class="bg-white absolute top-1/2 -translate-y-1/2 -left-5 w-5 border border-solid border-grayScale200 border-r-0 h-20 text-orangeThin rounded-tl-md rounded-bl-md"
      >
        <el-icon size="20">
          <ArrowRight v-if="isOpen" />
          <ArrowLeft v-else />
        </el-icon>
      </button>
    </div>

    <div class="pl-5 overflow-auto hideScroll w-full">
      <TeachersLessons />
    </div>
  </div>
</template>
<script setup>
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue";
import { ref } from "vue";
import TeachersLessons from "@/modules/Administration/widgets/Lessons/TeachersLessons.vue";
const isOpen = ref(false);
</script>

<style lang="scss">
.scheduleCollapse {
  .teachers-lessons {
    width: 100% !important;
    height: 95vh !important;
  }
}
</style>
