<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/groups-opening" class="sidebar__link" v-if="showRouter">
      <img src="@/assets/img/group-starter.png" alt="" />
      <p>{{ $t("Sidebar.managing") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="showRouter">
    <router-link to="/admin-new-leads" class="sidebar__link">
      <img src="@/assets/img/group-starter.png" alt="" />
      <p>Leads</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-students" class="sidebar__link">
      <img src="@/assets/img/students-sidebar-icon.png" alt="" />
      <p>{{ $t("Sidebar.students-list") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-groups" class="sidebar__link">
      <img src="@/assets/img/groups-sidebar-icon.png" alt="" />
      <p>{{ $t("Sidebar.groups-list") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/admin-space-shop" class="sidebar__link">
      <img src="@/assets/img/shop-sidebar-icon.png" alt="" />
      <p>{{ $t("Sidebar.space-shop") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/admin-mentors" class="sidebar__link">
      <img src="@/assets/img/mentors-icon.png" alt="mentor" />
      <p>{{ $t("Sidebar.mentors") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-sms" class="sidebar__link">
      <img src="@/assets/img/sms-icon.png" alt="sms" />
      <p>{{ $t("Sidebar.sms") }}</p>
    </router-link>
  </div>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/teacher-statistics" class="sidebar__link">
      <img src="@/assets/img/academy-statistics.png" alt="" />
      <p>Teachers rating</p>
    </router-link>
  </div> -->
  <!-- <div class="sidebar__link-wrapper" v-if="+branchModmeId === 3511 || isTest">
    <router-link to="/admin-group-open" class="sidebar__link">
      <img src="@/assets/img/group-icon-white.png" alt="" />
      <p>Groups</p>
    </router-link>
  </div> -->
  <div class="sidebar__link-wrapper">
    <router-link to="/products-management" class="sidebar__link">
      <img src="@/assets/img/projects-logo.png" alt="" />
      <p>{{ $t("Sidebar.axo") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-coins" class="sidebar__link">
      <i class="fa-solid fa-coins text-2xl"></i>
      <p>Coins</p>
    </router-link>
  </div>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/retentions" class="sidebar__link">
      <img src="@/assets/img/retention-sidebar-icon.png" alt="" />
      <p>{{ $t("Sidebar.retentions") }}</p>
    </router-link>
  </div> -->
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/admin-lessons-table" class="sidebar__link">
      <img src="@/assets/img/projects-logo.png" alt="" />
      <p>Lessons Schelude</p>
    </router-link>
  </div> -->
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/admin-space-shop" class="sidebar__link">
      <img src="@/assets/img/academy-statistics.png" alt="" />
      <p>Space shop</p>
    </router-link>
  </div> -->
</template>

<script setup>
import { computed } from "vue";
import store from "@/store";

const showRouter = computed(() => {
  const branch = store.state.branches.branch_list?.find(
    (branch) => branch.id == store.state.branches.active_branch
  );

  return (
    branch?.title?.toLowerCase().includes("maksim") ||
    branch?.title?.toLowerCase().includes("sergeli")
  );
});
</script>

<style></style>
