import api from "@/apiConfig/api";

export const getSlots = async () => {
  return await api.get("/users/slots_list");
};
export const getTutors = async (role, branch_id) => {
  return await api.get("/users", {
    params: {
      role: role,
      branch_id,
    },
  });
};
export const getRooms = async (company_id) => {
  return await api.get("/company/room", {
    params: {
      branch_id: company_id,
    },
  });
};
export const getExtraLessons = async (from, till) => {
  return await api.get("/course_elements/extra_lessons", {
    params: {
      from_date: from,
      till_date: till,
    },
  });
};

export const getTeachersSchedule = async (branch_id, days) => {
  return await api.get("/courses/teachers_schedule", {
    params: {
      days: days,
      branch_id: branch_id,
    },
  });
};
