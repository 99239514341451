export default {
  namespaced: true,
  state() {
    return {};
  },
  mutations: {
    publish(state, payload) {
      if (!state[payload]) {
        state[payload] = false;
      }
      state[payload] = !state[payload];
    },
  },
  actions: {},
  getters: {
    getEventState(state) {
      return state.eventState;
    },
  },
};
