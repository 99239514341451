import axios from "axios";
import store from "@/store";

const isTest = process.env.VUE_APP_MODAL_LOGIN || false;
let authToken = localStorage.getItem("access_token");

const api = axios.create({
  baseURL: isTest
    ? "https://test.api.marsit.uz/api/v1"
    : "https://api.marsit.uz/api/v1",
  headers: {
    Authorization: authToken ? `Bearer ${authToken}` : "",
    "Content-Type": "application/json", // Assuming JSON is the common content type
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await api.get("/auth/token", {
          headers: { Authorization: `Bearer ${refreshToken}` },
        });

        if (response.status === 200) {
          localStorage.setItem("access_token", response.data.access_token);
          authToken = response.data.access_token;
          originalRequest.headers["Authorization"] =
            "Bearer " + response.data.access_token;
          return api(originalRequest);
        }
      } catch (refreshError) {
        // Handle refresh error
        store.commit("auths/logout");
        return Promise.reject(refreshError);
      }
    }

    if (error?.response?.status.toString().startsWith("5")) {
      alert("Backend error");
    }

    // Log the error for further investigation
    console.error("Request failed:", error);

    // Reject the promise with the original error
    return Promise.reject(error);
  }
);

export default api;
