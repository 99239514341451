import api from "@/apiConfig/api";

api.defaults.withCredentials = true;

export const getMarks = async (group_id, course_element_id) => {
  return await api.get(
    `/course_elements/group/${group_id}/course_element/${course_element_id}/student_projects`
  );
};
export const getDetails = async (group_id) => {
  return await api.get(
    `/course_elements/group/${group_id}/course_element/answer_details`
  );
};

export const changeAnswerStatus = async (answer_id) => {
  return await api.post(`/courses/change_status?answer_id=${answer_id}`);
};
