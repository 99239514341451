import { createStore, createLogger } from "vuex";
import auths from "./modules/auths.module";
import groups from "./modules/groups.module";
import pubsub from "./modules/pubsub.module";
import branches from "./modules/branches.module";
import phone from "./modules/phone.module";
import roles from "./modules/roles.module";
import set from "./modules/set.module";
import payment from "./modules/payment.module";
import user from "./modules/user.module";
import sms from "./modules/sms.module";
import courses from "./modules/courses.module";
import certificate from "./modules/certificate.module";
import finance from "./modules/finance.module";

const plugins = [];

if (process.env.NODE_ENV === "development") {
  plugins.push(createLogger());
}

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    groups,
    auths,
    pubsub,
    branches,
    phone,
    roles,
    set,
    payment,
    user,
    sms,
    courses,
    certificate,
    finance,
  },
});
