<template>
  <div class="not-found">
    <p class="not-found__title">{{ $t('404-page') }}</p>
    <router-link class="not-found__btn" to="/">{{ $t('go-back') }}</router-link>
  </div>
</template>

<script>
export default {};
</script>
