<template>
  <template v-if="teacherMain">
    <div class="sidebar__link-wrapper" v-if="!isManager">
      <router-link to="/teacher-salary" class="sidebar__link">
        <img src="@/assets/img/mentors-icon.png" alt="" />
        <p>Salary</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/academy-teacher-list" class="sidebar__link">
        <img src="@/assets/img/mentors-academy.png" alt="" />
        <p>{{ $t("Sidebar.mentors-tutors") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/teacher-statistics" class="sidebar__link">
        <img src="@/assets/img/academy-statistics.png" alt="" />
        <p>{{ $t("Sidebar.teachers-stats") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/teacher-audit" class="sidebar__link">
        <img src="@/assets/img/audit-icon.png" alt="" />
        <p>{{ $t("Sidebar.qa-audit") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/teacher-audit-statistics" class="sidebar__link">
        <img src="@/assets/img/academy-statistics.png" alt="" />
        <p>{{ $t("Sidebar.academy-statistics") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/teachers-grading" class="sidebar__link">
        <img src="@/assets/img/academy-statistics.png" alt="" />
        <p>{{ $t("Sidebar.teachers-grading") }}</p>
      </router-link>
    </div>

    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/teachers-lessons-schedule" class="sidebar__link">
        <img src="@/assets/img/schedule-white.png" alt="" />
        <p>{{ $t("Sidebar.schedule") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper" v-if="isManager">
      <router-link to="/generate-certificate" class="sidebar__link">
        <img src="@/assets/img/certificate-sidebar-icon.png" alt="" />
        <p>{{ $t("Sidebar.certificate") }}</p>
      </router-link>
    </div>
  </template>
  <template v-else>
    <div class="sidebar__link-wrapper">
      <router-link class="sidebar__link" :to="'/group-coins/' + group_id">
        <img src="@/assets/img/coins-logo.png" alt="" />
        <p>{{ $t("Sidebar.coins") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper">
      <router-link class="sidebar__link" :to="'/group-projects/' + group_id">
        <img src="@/assets/img/projects-logo.png" alt="" />
        <p>{{ $t("Sidebar.projects") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper">
      <router-link class="sidebar__link" to="/group-methodology">
        <img src="@/assets/img/methodology-logo.png" alt="" />
        <p>{{ $t("Sidebar.methodology") }}</p>
      </router-link>
    </div>

    <div class="sidebar__link-wrapper" v-if="hideSkills">
      <router-link class="sidebar__link" to="/group-students-skills">
        <img src="@/assets/img/skills-logo.png" alt="" />
        <p>{{ $t("Sidebar.skills") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper">
      <router-link class="sidebar__link" :to="'/group-attendance/' + group_id">
        <img src="@/assets/img/attendance-route-icon.png" alt="" />
        <p>{{ $t("Sidebar.attendance") }}</p>
      </router-link>
    </div>
    <div class="sidebar__link-wrapper">
      <router-link class="sidebar__link" to="/group-examine-date">
        <img src="@/assets/img/exam-logo.png" alt="" />
        <p>{{ $t("Sidebar.examine") }}</p>
      </router-link>
    </div>
  </template>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const group_id = ref(localStorage.getItem("group_id"));
    const isTest = process.env.VUE_APP_MODAL_LOGIN || false;
    const hideSkills = computed(() => {
      if (localStorage.getItem("group_name")) {
        return localStorage.getItem("group_name").includes("ST");
      } else {
        return false;
      }
    });

    const isManager = localStorage.getItem("isManager");

    const route = useRoute();

    const teacherMain = ref(true);

    const checkRoute = (val) => {
      if (
        val.path.includes("teacher") ||
        val.path.includes("tutor") ||
        val.path.includes("certificate")
      ) {
        teacherMain.value = true;
      } else {
        teacherMain.value = false;
      }
      group_id.value = localStorage.getItem("group_id");
      hideSkills;
    };
    watch(route, (val) => {
      checkRoute(val);
    });

    onBeforeMount(() => {
      checkRoute(route);
    });

    return {
      hideSkills,
      teacherMain,
      isManager,
      group_id,
      isTest,
    };
  },
};
</script>

<style></style>
