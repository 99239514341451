<template>
  <the-header />
  <main>
    <side-bar v-if="showSidebar" />
    <router-view
      :style="[
        { marginLeft: showSidebar ? '93px' : '0' },
        { width: !showSidebar && '100%' },
      ]"
    />
  </main>
</template>

<script>
// import SidebarCom from "@/components/SidebarCom.vue";
import SideBar from "@/components/SideBar.vue";
import TheHeader from "@/components/TheHeader.vue";
import { decryptText } from "@/utils/cryptFunc";

export default {
  components: { SideBar, TheHeader },
  data() {
    return {
      showSidebar: true,
      isManager: !!localStorage.getItem("isManager"),
      role: decryptText(localStorage.getItem("role")),
      sideBarRoles: [
        "teacher",
        "audit",
        "admin",
        "finance",
        "examiner",
        "instructor",
        "kurator",
        "marketing",
        "axo",
        "hunter",
        "tutor",
        "moderator"
      ],
    };
  },
  beforeMount() {
    this.routeNameCheck();
  },
  watch: {
    $route() {
      this.routeNameCheck();
    },
  },
  methods: {
    routeNameCheck() {
      if (this.sideBarRoles.includes(this.role)) {
        this.showSidebar = true;
        if (this.role === "axo" && window.innerWidth < 576) {
          this.showSidebar = false;
        }
      } else {
        this.showSidebar = false;
      }
    },
  },
};
</script>
