import { createI18n } from "vue-i18n";
import ru from "./ru.json";
import uz from "./uz.json";
import en from "./en.json";

const defaultLocale = "uz";
const lang = localStorage.getItem("lang");

const messages = {
  uz,
  en,
  ru,
};

export const i18n = createI18n({
  legacy: false,
  fallbackLocale: "uz",
  locale: lang || defaultLocale,
  messages,
});
