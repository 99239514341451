import apiV2 from "@/apiConfig/api-v2";

export default {
  state() {
    return {
      transactions: [],
      orders: [],
      subscriptions: [],
      stdSubscriptions: [],
      stdDiscounts: [],
    };
  },
  actions: {
    async getTransactions(ctx, student_id) {
      ctx.state.transactions = await apiV2
        .get(`/payment/transaction/${student_id}`, {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getOrders(ctx, student_id) {
      ctx.state.orders = await apiV2
        .get(`/orders/${student_id}`, {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getSubscriptions(ctx) {
      ctx.state.subscriptions = await apiV2
        .get("/subscription")
        .then((res) => res.data)
        .catch(() => []);
    },
    async getStdSubscriptions(ctx, student_id) {
      ctx.state.stdSubscriptions = await apiV2
        .get(`/subscription/student/${student_id}`, {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getStdDiscounts(ctx, student_id) {
      ctx.state.stdDiscounts = await apiV2
        .get("orders/dicounts", {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
  },
};
