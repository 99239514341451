<template>
  <div
    :class="['teacher-lesson relative', `teacher-lesson--${course}`]"
    v-if="group"
  >
    <div class="relative">
      <span
        v-if="interval >= -30"
        class="text-whiteColor text-[10px] p-[2px] rounded-[5px] absolute -top-5 -right-4"
        :class="{
          ' bg-[#52acd2]': interval > -30 && interval < 0,
          ' bg-orangeThin': interval >= 0,
        }"
      >
        {{ interval > 0 ? "start in" : interval < 0 ? "new" : "today" }}
        {{ interval === 0 ? " " : Math.abs(interval) + " days" }}
      </span>

      <router-link :to="'/teacher-info/' + group.user.id" class="teacher-name">
        {{ group.user.first_name + " " + group.user.last_name }}
      </router-link>
      <router-link :to="'/group-attendance/' + group.id" class="group-name">{{
        group.name
      }}</router-link>
    </div>
    <p class="date">
      {{ DateTime.fromISO(group.date_started).toFormat("dd MMM") }} -
      {{ DateTime.fromISO(group.date_finished).toFormat("dd MMM") }}
    </p>
    <div class="flex items-center mt-1 justify-between">
      <p class="date">
        {{ group.lesson_start_time.slice(0, 5) }} -
        {{ group.lesson_end_time.slice(0, 5) }}
      </p>
      <p class="bg-white text-[10px] px-1 font-bold py-[2px] rounded">
        {{ group.students?.length }}
      </p>
    </div>
  </div>
</template>
<script>
import { toRefs, computed, ref } from "vue";
import { DateTime } from "luxon";
export default {
  props: ["group"],

  setup(props) {
    const { group } = toRefs(props);

    const course = computed(() => {
      return group.value.name.slice(0, group.value.name.indexOf("-"));
    });

    const interval = computed(() => {
      const currentDate = new Date().getTime();
      const startDate = new Date(props.group.date_started);

      const day = startDate - currentDate;

      return Math.ceil(day / (1000 * 60 * 60 * 24));
    });

    return {
      DateTime,
      course,
      interval,
    };
  },
};
</script>
<style lang="scss">
@import "./style/extra-lesson.scss";
</style>
