<template>
  <div>
    <search-bar
      :headerSearch="true"
      @search="getQuery($event)"
      @clear="handleClear"
    />
    <div
      v-if="showWrapper"
      class="absolute z-[111111111111] w-[514px] overflow-x-hidden bg-white rounded-sm px-4 py-3 shadow-lg max-h-[500px] overflow-y-auto wrapperr"
    >
      <div class="searchingg w-[490px]">
        <div class="Studentss flex-col items-center">
          <div
            class="header_word text-start border border-solid border-grayScale100 border-x-0 border-t-0 py-1"
          >
            <p class="font-extrabold text-[#FF805D]">
              {{ $t("Admin.student") }}
            </p>
          </div>
          <div v-if="students && students?.length">
            <div
              v-for="(student, index) in students?.slice(0, 5)"
              :key="index"
              class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
            >
              <router-link
                :to="'/student-profile/' + student.id"
                @click="showWrapper = false"
              >
                <p class="text-grayScale400 font-medium text-[12px]">
                  <span :style="{ color: isMatching(student.name).color1 }">{{
                    isMatching(student.name).beforeMatch
                  }}</span>
                  <span :style="{ color: isMatching(student.name).color2 }">{{
                    isMatching(student.name).match
                  }}</span>
                  <span :style="{ color: isMatching(student.name).color1 }">{{
                    isMatching(student.name).afterMatch
                  }}</span>
                </p>
              </router-link>
              <p class="font-medium text-grayScale600 text-[12px]">
                {{
                  findGroupWithStatus(student.groups, 5)?.name ||
                  (student.groups && student.groups.length > 0
                    ? student.groups[student.groups.length - 1].name
                    : "")
                }}
              </p>
            </div>
            <el-collapse v-model="collapse" v-if="students?.length > 5">
              <el-collapse-item
                :title="students?.slice(5).length + ' more results'"
              >
                <div
                  v-for="(student, index) in students?.slice(5)"
                  :key="index"
                  class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
                >
                  <router-link
                    :to="'/student-profile/' + student.id"
                    @click="showWrapper = false"
                  >
                    <p class="text-grayScale400 font-medium text-[12px]">
                      <span
                        :style="{ color: isMatching(student.name).color1 }"
                        >{{ isMatching(student.name).beforeMatch }}</span
                      >
                      <span
                        :style="{ color: isMatching(student.name).color2 }"
                        >{{ isMatching(student.name).match }}</span
                      >
                      <span
                        :style="{ color: isMatching(student.name).color1 }"
                        >{{ isMatching(student.name).afterMatch }}</span
                      >
                    </p>
                  </router-link>
                  <p class="font-medium text-grayScale600 text-[12px]">
                    {{
                      findGroupWithStatus(student.groups, 5)?.name ||
                      (student.groups && student.groups.length > 0
                        ? student.groups[student.groups.length - 1].name
                        : "")
                    }}
                  </p>
                </div>
                <button
                  @click="collapse = false"
                  class="flex items-center justify-center gap-2 text-[14px] w-full py-3"
                >
                  {{ $t("Admin.show-less") }}
                  <img
                    src="@/assets/img/upload.png"
                    class="w-[10px] h-[10px]"
                    alt="less"
                  />
                </button>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div v-else>
            <p class="pt-4">{{ $t("Admin.no-students") }}</p>
          </div>
        </div>
        <div
          class="border border-solid border-[#FF805D] border-x-0 border-t-0 py-3 w-full"
        ></div>
        <div class="Mentorss pt-3" v-if="!noMentor">
          <div
            class="header_word text-start border border-solid border-grayScale100 border-x-0 border-t-0 py-1"
          >
            <p class="font-extrabold text-[#FF805D]">
              {{ $t("Admin.mentor") }}
            </p>
          </div>
          <div v-if="mentors && mentors?.length">
            <div
              v-for="(mentor, index) in mentors"
              :key="index"
              class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
            >
              <router-link
                :to="'/teacher-info/' + mentor.id"
                @click="showWrapper = false"
              >
                <p class="text-grayScale400 font-medium text-[12px]">
                  <span
                    :style="{
                      color: isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).color1,
                    }"
                  >
                    {{
                      isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).beforeMatch
                    }}
                  </span>
                  <span
                    :style="{
                      color: isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).color2,
                    }"
                  >
                    {{
                      isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).match
                    }}
                  </span>
                  <span
                    :style="{
                      color: isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).color1,
                    }"
                  >
                    {{
                      isMatching(
                        `${mentor?.first_name} ${mentor?.last_name}`,
                        true
                      ).afterMatch
                    }}
                  </span>
                </p>
              </router-link>
              <p class="font-medium text-grayScale600 text-[12px]">
                {{ mentor?.role === null ? "Mentor" : "Tutor" || "No data" }}
              </p>
            </div>
          </div>
          <div v-else>
            <p class="pt-4">{{ $t("Admin.no-mentors") }}</p>
          </div>
        </div>
        <div
          class="border border-solid border-[#FF805D] border-x-0 border-t-0 py-3 w-full"
        ></div>
        <div class="Groupss pt-3">
          <div
            class="header_word text-start border border-solid border-grayScale100 border-x-0 border-t-0 py-1"
          >
            <p class="font-extrabold text-[#FF805D]">{{ $t("Admin.group") }}</p>
          </div>
          <div v-if="groups && groups.length">
            <div
              class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
              v-for="group in groups"
              :key="group.id"
            >
              <router-link
                :to="'/group-attendance/' + group.id"
                @click="saveGroupInfo(group), (showWrapper = false)"
              >
                <p class="text-grayScale400 font-medium text-[12px]">
                  <span :style="{ color: isMatchingGroup(group.name).color1 }">
                    {{ isMatchingGroup(group.name).beforeMatch }}
                  </span>
                  <span :style="{ color: isMatchingGroup(group.name).color2 }">
                    {{ isMatchingGroup(group.name).match }}
                  </span>
                  <span :style="{ color: isMatchingGroup(group.name).color1 }">
                    {{ isMatchingGroup(group.name).afterMatch }}
                  </span>
                </p>
              </router-link>
            </div>
          </div>
          <div v-else>
            <p class="pt-4">{{ $t("Admin.no-groups") }}</p>
          </div>
        </div>
        <div
          class="border border-solid border-[#FF805D] border-x-0 border-t-0 py-3 w-full"
        ></div>
        <div class="phones pt-3">
          <div
            class="header_word text-start border border-solid border-grayScale100 border-x-0 border-t-0 py-1"
          >
            <p class="font-extrabold text-[#FF805D]">{{ $t("Admin.phone") }}</p>
          </div>
          <div v-if="phones && phones?.length">
            <div
              class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
              v-for="phone in phones.slice(0, 5)"
              :key="phone.id"
            >
              <router-link
                :to="'/student-profile/' + phone.id"
                @click="showWrapper = false"
              >
                <p class="text-grayScale400 font-medium text-[12px]">
                  <span
                    :style="{
                      color: isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(', ') ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(', ') ||
                          phone.data.phones.own.map((p) => p).join(', ')
                      ).color1,
                    }"
                    >{{
                      isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(", ") ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(", ") ||
                          phone.data.phones.own.map((p) => p).join(", ")
                      ).beforeMatch
                    }}</span
                  >
                  <span
                    :style="{
                      color: isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(', ') ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(', ') ||
                          phone.data.phones.own.map((p) => p).join(', ')
                      ).color2,
                    }"
                    >{{
                      isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(", ") ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(", ") ||
                          phone.data.phones.own.map((p) => p).join(", ")
                      ).match
                    }}</span
                  >
                  <span
                    :style="{
                      color: isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(', ') ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(', ') ||
                          phone.data.phones.own.map((p) => p).join(', ')
                      ).color1,
                    }"
                    >{{
                      isMatching(
                        phone.data.phones.own.map((p) => p.phone).join(", ") ||
                          phone.data.phones.parent
                            .map((p) => p.phone)
                            .join(", ") ||
                          phone.data.phones.own.map((p) => p).join(", ")
                      ).afterMatch
                    }}</span
                  >
                </p>
              </router-link>
              <p class="font-medium text-grayScale600 text-[12px]">
                {{ phone.name }}
              </p>
            </div>
            <el-collapse v-model="collapse" v-if="phones?.length > 5">
              <el-collapse-item
                :title="phones?.slice(5).length + ' more results'"
              >
                <div
                  v-for="(phone, index) in phones?.slice(5)"
                  :key="index"
                  class="flex items-center justify-between py-1 px-3 border border-solid border-grayScale100 border-x-0 border-t-0 hover:bg-[#fafafa]"
                >
                  <router-link
                    :to="'/student-profile/' + phone.id"
                    @click="showWrapper = false"
                  >
                    <p class="text-grayScale400 font-medium text-[12px]">
                      <span
                        :style="{
                          color: isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(', ') ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(', ') ||
                              phone.data.phones.own.map((p) => p).join(', ')
                          ).color1,
                        }"
                        >{{
                          isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(", ") ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(", ") ||
                              phone.data.phones.own.map((p) => p).join(", ")
                          ).beforeMatch
                        }}</span
                      >
                      <span
                        :style="{
                          color: isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(', ') ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(', ') ||
                              phone.data.phones.own.map((p) => p).join(', ')
                          ).color2,
                        }"
                        >{{
                          isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(", ") ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(", ") ||
                              phone.data.phones.own.map((p) => p).join(", ")
                          ).match
                        }}</span
                      >
                      <span
                        :style="{
                          color: isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(', ') ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(', ') ||
                              phone.data.phones.own.map((p) => p).join(', ')
                          ).color1,
                        }"
                        >{{
                          isMatching(
                            phone.data.phones.own
                              .map((p) => p.phone)
                              .join(", ") ||
                              phone.data.phones.parent
                                .map((p) => p.phone)
                                .join(", ") ||
                              phone.data.phones.own.map((p) => p).join(", ")
                          ).afterMatch
                        }}</span
                      >
                    </p>
                  </router-link>
                  <p class="font-medium text-grayScale600 text-[12px]">
                    {{ phone.name }}
                  </p>
                </div>
                <button
                  @click="collapse = false"
                  class="flex items-center justify-center gap-2 text-[14px] w-full py-3"
                >
                  {{ $t("Admin.show-less") }}
                  <img
                    src="@/assets/img/upload.png"
                    class="w-[10px] h-[10px]"
                    alt="less"
                  />
                </button>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div v-else>
            <p class="pt-4">{{ $t("Admin.no-phones") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineComponent, ref, watchEffect } from "vue";
import { getStudents, getMentors, getGroups, getPhone } from "./api/get-search";
import SearchBar from "@/components/SearchBar.vue";

defineComponent(["SearchBar"]);
const props = defineProps(["branchId", "noMentor"]);

const searchInput = ref("");
const showWrapper = ref(false);
const collapse = ref(false);

const mentors = ref(null);
const students = ref(null);
const groups = ref(null);
const phones = ref(null);

const getQuery = (searchQuery) => {
  if (searchQuery.length) {
    searchInput.value = searchQuery;
    handleInput();
  } else {
    searchInput.value = null;
    showWrapper.value = false;
  }
};

const handleInput = async () => {
  showWrapper.value = searchInput.value.trim() !== "";
  if (showWrapper.value && searchInput.value.length > 2) {
    try {
      const teachers = await getMentors(
        "teacher",
        searchInput.value,
        props.branchId
      ).then((res) => res.data);
      const tutors = await getMentors(
        "tutor",
        searchInput.value,
        props.branchId
      ).then((res) => res.data);

      mentors.value = teachers.concat(tutors);

      students.value = await getStudents(
        searchInput.value,
        props.branchId
      ).then((res) => res.data.students);

      groups.value = await getGroups(props.branchId, searchInput.value).then(
        (res) => res.data
      );

      if (searchInput.value.length >= 4) {
        phones.value = await getPhone(props.branchId, searchInput.value).then(
          (res) => res.data.students
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};

const isMatching = (text, isMentor) => {
  const lowerText = text.toLowerCase();
  const lowerSearchInput = searchInput.value.toLowerCase();
  const index = lowerText.indexOf(lowerSearchInput);
  if (index === -1) {
    return {
      beforeMatch: text,
      match: "",
      afterMatch: "",
      color1: "#bdbdbd",
      color2: "#bdbdbd",
    };
  }
  const beforeMatch = text.slice(0, index);
  const match = text.slice(index, index + searchInput.value.length);
  const afterMatch = text.slice(index + searchInput.value.length);
  return {
    beforeMatch,
    match,
    afterMatch,
    color1: "#bdbdbd",
    color2: "#424242",
  };
};

const isMatchingGroup = (groupName) => {
  return isMatching(groupName, false);
};

const handleClear = () => {
  searchInput.value = "";
  students.value = null;
  mentors.value = null;
  groups.value = null;
  phones.value = null;
  showWrapper.value = false;
};

const saveGroupInfo = (group) => {
  localStorage.setItem(
    "group_details",
    JSON.stringify({
      start_date: group.date_started,
      end_date: group.date_finished,
      start_time: group.lesson_start_time,
      end_time: group.lesson_end_time,
      group_id: group.id,
      group_name: group.name,
      teacher_name: group?.user?.first_name + " " + group?.user?.last_name,
      days: group.days,
    })
  );
};

const findGroupWithStatus = (groups, status) => {
  if (!groups) return null;
  return groups.find((group) => group.status === status);
};
</script>

<style lang="scss">
.Studentss {
  .el-collapse-item__header {
    justify-content: center;
    border: none !important;
  }

  .el-collapse-item__arrow {
    margin: 0 !important;
  }

  .el-collapse {
    border: none !important;
  }
}
</style>
