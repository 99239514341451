import { ElMessage } from "element-plus";

export default {
  state() {
    return {
      phonePattern: /^\+998\s?[1-9]\d{8}$/,
      isClearPhoneInput: false,
    };
  },

  actions: {
    checkPhoneNumber(context, phoneNumber) {
      const pattern = context.state.phonePattern;
      const isValidPhoneNumber = pattern.test(phoneNumber);
      if (isValidPhoneNumber) {
        return true;
      } else {
        ElMessage({
          type: "warning",
          message: "Telefon nomer no'tog'ri formatda kiritildi",
        });
        return false;
      }
    },
    setIsClearPhoneInput(ctx) {
      ctx.state.isClearPhoneInput = !ctx.state.isClearPhoneInput;
    },
  },
};
