<template>
  <!-- <div class="pagination" :style="{display: totalPages <= 1 && 'none'}"> -->
  <div class="pagination">
    <button @click="toPrevPage" class="pagination__btn pagination__btn-prev">
      <span v-if="prev_icon">
        <i>
          {{ prev_icon }}
        </i>
      </span>
      <img v-else src="@/assets/img/pagination-prev-icon.png" alt="" />
    </button>
    <slot>
      <p class="pagination__page pagination__page--active">
        {{ activePage }} / {{ totalPages }}
      </p>
    </slot>
    <button @click="toNextPage" class="pagination__btn pagination__btn-next">
      <span v-if="next_icon">
        <i>
          {{ next_icon }}
        </i>
      </span>
      <img v-else src="@/assets/img/pagination-next-icon.png" alt="" />
    </button>
  </div>
</template>

<script>
import { onBeforeMount, ref, toRefs, watch } from "vue";
export default {
  props: [
    "totalElements",
    "elementsPerPage",
    "prev_icon",
    "next_icon",
    "elements",
    "activeCourse",
  ],
  emits: ["changeActivePage"],
  setup(props, { emit }) {
    const activePage = ref(1);

    const { totalElements, elementsPerPage, elements } = toRefs(props);

    const totalPages = ref(
      Math.ceil(totalElements.value / elementsPerPage.value)
    );

    const toPrevPage = async () => {
      if (activePage.value !== 1) {
        activePage.value -= 1;
        return false;
      } else {
        return true;
      }
    };

    const toNextPage = () => {
      if (activePage.value !== totalPages.value) {
        activePage.value += 1;
        return false;
      } else {
        return true;
      }
    };

    const slicedArray = ref([]);

    const renderElements = () => {
      const startIndex = (activePage.value - 1) * elementsPerPage.value;
      const endIndex = startIndex + elementsPerPage.value;
      if (elements?.value) {
        slicedArray.value = elements?.value.slice(startIndex, endIndex);
      }
    };

    onBeforeMount(() => {
      renderElements();
      emit("changeActivePage", {
        changedArr: slicedArray.value,
      });
    });

    watch(totalElements, () => {
      totalPages.value = Math.ceil(totalElements.value / elementsPerPage.value);
    });

    watch(activePage, (val) => {
      renderElements();
      emit("changeActivePage", {
        activePage: val,
        changedArr: slicedArray.value,
      });
    });

    // const {activeCourse} = toRefs(props)

    watch(elements, (val) => {
      elements.value = val;
      renderElements();
      emit("changeActivePage", {
        changedArr: slicedArray.value,
      });
    });

    return {
      activePage,
      toPrevPage,
      toNextPage,
      totalPages,
    };
  },
};
</script>
