<template>
  <header class="header">
    <div class="header-container flex justify-between items-center">
      <router-link :to="'/' + role + '-dashboard'">
        <img class="header-logo" src="@/assets/img/mars-logo.png" alt="logo" />
      </router-link>

      <div v-if="isSuperUser" class="header-btn-wrapper">
        <button class="header-btn" @click="redirectSuperUser('audit')">
          {{ $t("audit") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('admin')">
          {{ $t("admin") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('teacher')">
          {{ $t("teacher") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('instructor')">
          {{ $t("instructor") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('call')">
          {{ $t("call-center") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('examiner')">
          {{ $t("examiner") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('hunter')">
          {{ $t("hunter") }}
        </button>
        <button class="header-btn" @click="redirectSuperUser('teacher', true)">
          {{ $t("academy") }}
        </button>
      </div>
      <MainSearch
        :noMentor="(noMentor = true)"
        v-if="role == 'hunter'"
        class="mr-10"
      />
      <div class="flex gap-3">
        <div
          class="border-0 border-r-[1px] border-solid border-grayScale300 pr-2 flex items-center"
        >
          <p class="font-normal text-grayScale800">
            {{ first_name }} {{ last_name }}
          </p>
        </div>
        <div
          class="border-0 border-r-[1px] border-solid border-grayScale300 pr-3"
        >
          <el-select
            v-model="selectedLanguage"
            class="!w-[70px]"
            placeholder="Select Language"
            @change="changeLanguage"
          >
            <el-option
              v-for="lang in langOptions"
              :key="lang.value"
              :label="lang.label"
              :value="lang.value"
            />
          </el-select>
        </div>
        <div>
          <div
            v-if="role === 'hunter' || role === 'finance'"
            class="border-0 border-r-[1px] border-solid border-grayScale300 pr-3"
          >
            <el-select
              v-model="$store.state.branches.active_branch"
              class="w-[180px]"
              @change="branchSelected"
            >
              <el-option
                v-for="branch in $store.state.branches.branch_list"
                :key="branch.id"
                :label="branch.title"
                :value="branch.id"
              />
            </el-select>
          </div>
          <div
            class="border-0 border-r-[1px] border-solid border-grayScale300 pr-3"
            v-if="role === 'admin' && adminBranches?.length > 1"
          >
            <el-select
              v-model="$store.state.branches.active_branch"
              class="w-[180px]"
              @change="branchSelected"
            >
              <el-option
                v-for="branch in adminBranches"
                :key="branch.id"
                :label="branch.title"
                :value="branch.id"
              />
            </el-select>
          </div>
        </div>

        <div class="header-right__logout">
          <button
            class="cursor-pointer flex justify-center items-center w-[30px] h-[30px]"
            @click="logout()"
          >
            <img
              class="object-fill"
              src="@/assets/img/logout-icon-red.png"
              alt=""
              width="20"
              height="20"
            />
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { langMixin } from "@/mixins/lang-value.mixin";
import { decryptText, encryptText } from "@/utils/cryptFunc";
import MainSearch from "@/modules/Admin/features/MainSearch/MainSearch.vue";
import store from "@/store";
import api from "@/apiConfig/api";

export default {
  mixins: [langMixin],
  components: {
    MainSearch,
  },

  data() {
    return {
      first_name: localStorage.getItem("firstname"),
      last_name: localStorage.getItem("lastname"),
      role: localStorage.getItem("role"),
      selectedLanguage: localStorage.getItem("lang") || "uz",
      langOptions: [
        { value: "en", label: "En" },
        { value: "ru", label: "Ru" },
        { value: "uz", label: "Uz" },
      ],
      role: decryptText(localStorage.getItem("role")),
      isSuperUser: localStorage.getItem("isSuperUser"),
      adminBranches: JSON.parse(localStorage.getItem("admin_branches")),
    };
  },

  methods: {
    logout() {
      this.$router.push("/");
      this.$store.commit("auths/logout");
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang); // save selected language to localStorage
    },

    redirectSuperUser(role, isManager) {
      this.$router.push({ name: `${role}-main` });
      localStorage.setItem("role", encryptText(role));
      localStorage.setItem("isManager", isManager);
    },
    branchSelected(val) {
      localStorage.setItem("branchIdInHeader", val);
      localStorage.setItem("branch_id", val);
      if (this.role === "admin") {
        this.saveExternalId(val);
      }
    },
    saveExternalId() {
      const findBranch = this.adminBranches?.find(
        (b) => b.id == store.state.branches.active_branch
      );
      if (findBranch) {
        localStorage.setItem("branch_external_id", findBranch.external_id);
        store.commit("setActiveBranchExternalId", findBranch.external_id);
      }
    },
  },
  mounted() {
    if (this.role === "hunter" || this.role === "finance") {
      this.$store.dispatch("getBranches");

      const branchId = localStorage.getItem("branchIdInHeader");
      if (branchId) {
        store.commit("setActiveBranchId", +branchId);
      }
    }

    if (this.role === "admin" && !this.adminBranches?.length) {
      this.$store.dispatch("getBranches");

      api.get("/me").then((res) => {
        this.adminBranches = res.data?.branches;
        localStorage.setItem(
          "admin_branches",
          JSON.stringify(res.data?.branches) || []
        );
        store.commit("setActiveBranchId", res.data?.branches[0]?.id);
      });
      this.saveExternalId();
    }

    if (this.role === "admin" && this.adminBranches) {
      this.$store.dispatch("getBranches");

      // save branch id to localStorage
      const branchIdInLocalstorage = localStorage.getItem("branch_id");

      const branch_id = branchIdInLocalstorage
        ? +branchIdInLocalstorage
        : this.adminBranches[0]?.id;

      store.commit("setActiveBranchId", branch_id);
    }

    this.saveExternalId();
  },
};
</script>
