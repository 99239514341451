import apiV2 from "@/apiConfig/api-v2";

export default {
  state() {
    return {
      groupSmsHistory: [],
      studentSmsHistory: [],
      allSmsHistory: [],
    };
  },
  actions: {
    async getGroupSmsHistory(ctx, group_id) {
      await apiV2
        .get("broadcasts/sms/history", {
          params: {
            group_id: group_id,
          },
        })
        .then((res) => {
          ctx.state.groupSmsHistory = res.data.reverse();
        });
    },
    async getStudentSmsHistory(ctx, student_id) {
      await apiV2
        .get("broadcasts/sms/history", {
          params: {
            student_id,
          },
        })
        .then((res) => {
          ctx.state.studentSmsHistory = res.data.reverse();
        });
    },
    async getAllSmsHistory(ctx) {
      await apiV2.get("broadcasts/sms/history").then((res) => {
        ctx.state.allSmsHistory = res.data.reverse();
      });
    },
  },
};
