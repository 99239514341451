import api from "@/apiConfig/api";
import apiV2 from "@/apiConfig/api-v2";
import { ElMessage } from "element-plus";

export default {
  state() {
    return {
      teacher_name: "",
      time: "",
      group: "",
      teacher_id: "",
      drag_student_id: null,
      old_group_id: null,
      get_col_id: null,
      dragStdName: "",
      droppedGrName: "",
      grLeads: {},
      colGrps: {},
      camedLeads: {},
      leadColPage: null,
      setDragged: false,
      colDragId: null,
      colDragPage: null,
      errStatus: {},
      newGrSbs: {},
      oldGrSbs: {},
      draggedGr: {},
      droppedGr: {},
      draggedGrPage: null,
      droppedGrPage: null,
      startedGrSbs: {},
      checkTransferData: {},
      isShowCols: {},
      draggedStd: null,
      searchedGroups: [],
    };
  },
  mutations: {
    SETTEACHERINFO(state, payload) {
      state.teacher_name = payload.teacher_name;
      state.time = payload.time;
      state.group = payload.group;
      state.teacher_id = payload.teacher_id;
    },
    SET_SEARCHED_GROUPS(state, groups) {
      state.searchedGroups = groups || [];
    },
  },
  actions: {
    setGrSbs(ctx, { type, sbs }) {
      if (type == "old") ctx.state.oldGrSbs = sbs;
      else ctx.state.newGrSbs = sbs;
    },
    setGrPage(ctx, { type, page }) {
      if (type == "dragged") ctx.state.draggedGrPage = page;
      else if (type === "dropped") ctx.state.droppedGrPage = page;
    },
    setTeacherInfoAction({ commit }, payload) {
      commit("SETTEACHERINFO", payload);
    },
    setDragStudentId(context, student_id) {
      context.state.drag_student_id = student_id;
    },
    setDraggedStd(context, std) {
      context.state.draggedStd = std;
    },
    setOldGroupId(context, group_id) {
      context.state.old_group_id = group_id;
    },
    setDragStdName(ctx, name) {
      ctx.state.dragStdName = name;
    },
    setDroppedGrName(ctx, name) {
      ctx.state.droppedGrName = name;
    },
    setLeadColPage(ctx, page) {
      ctx.state.leadColPage = page;
    },
    setSetIsDragged(ctx, isDragged) {
      ctx.state.setDragged = isDragged;
    },
    setGroupDragId(ctx, { colDragId, page }) {
      ctx.state.colDragId = colDragId;
      ctx.state.colDragPage = page;
    },
    async getGrLeads(ctx, groupId) {
      ctx.state.grLeads[groupId] = await api
        .get(`/groups/${groupId}`, {
          params: {
            group_id: groupId,
          },
        })
        .then((res) => res.data.students)
        .catch(() => []);
    },
    async addLeadToGr(ctx, { group_id, student_id }) {
      ctx.state.errStatus[group_id] = null;

      await api
        .post(`/groups/${group_id}/students/${student_id}`, {
          group_id,
          student_id,
        })
        .then((res) => {
          if (res.status === 207) {
            ctx.state.startedGrSbs[group_id] = res.data;
          } else
            ElMessage({
              message: "Guruhga qo'shildi",
              type: "success",
            });

          ctx.state.errStatus[group_id] = res.status;
        })
        .catch((err) => {
          console.log(err);
          ctx.state.errStatus[group_id] = null;

          ElMessage({
            type: "error",
            message: "Something went wrong",
          });
          // const status = err?.response?.status;
          // switch (status) {
          //   case 406:
          //     ctx.state.errStatus[group_id] = 406;
          //     break;
          //   default:
          //     ctx.state.errStatus[group_id] = null;
          //     ElMessage({
          //       type: "error",
          //       message: "Something went wrong",
          //     });

          //     break;
          // }
        });
    },
    async transferStd(ctx, { student_id, old_group_id, new_group_id }) {
      await api
        .post("/groups/students/transfer", {
          student_id,
          old_group_id,
          new_group_id,
        })
        .then(() => {
          ElMessage({
            type: "success",
            message: "Guruhga o'tkazildi",
          });
        })
        .catch((err) => {
          ElMessage({
            type: "error",
            message: err.response.data.detail.message,
          });
        });
    },
    async getColGrps(
      ctx,
      {
        course_id,
        branch_id,
        sorting = false,
        page = 1,
        name = null,
        days = "all",
      }
    ) {
      if (!ctx.state.colGrps[course_id]) ctx.state.colGrps[course_id] = {};
      if (!ctx.state.colGrps[course_id][page])
        ctx.state.colGrps[course_id][page] = [];

      await apiV2
        .get("/groups/search", {
          params: {
            course_id,
            branch_id,
            sorting,
            page,
            name,
            days,
          },
        })
        .then((res) => {
          ctx.state.colGrps[course_id][page] = res.data;
        })
        .catch(() => {
          ctx.state.colGrps[course_id][page] = [];
        });
    },
    async getCamedLeads(ctx, { branch_id, page = 1, status = null }) {
      if (!ctx.state.camedLeads[page]) ctx.state.camedLeads[page] = [];

      ctx.state.camedLeads[page] = await api
        .get(`/leads/${branch_id}`, {
          params: {
            branch_id,
            page,
            status,
          },
        })
        .then((res) => res.data.leads)
        .catch(() => []);
    },
    async searchCamedLeads(ctx, { branch_id, name, page = 1 }) {
      if (!ctx.state.camedLeads[page]) ctx.state.camedLeads[page] = [];
      await api
        .get("/leads/search", {
          params: {
            branch_id: branch_id,
            name: name,
          },
        })
        .then((res) => {
          ctx.state.camedLeads[page] = res.data;
        })
        .catch(() => {
          ctx.state.camedLeads[page] = [];
        });
    },
    async setGr(ctx, { type, gr }) {
      if (type === "dragged") {
        ctx.state.draggedGr = gr;
      } else if (type === "dropped") {
        ctx.state.droppedGr = gr;
      }
    },
    setCheckTransferData(ctx, data) {
      ctx.state.checkTransferData = data;
    },
    async checkLeadTransfer(ctx, { student_id, old_group_id, new_group_id }) {
      await apiV2
        .post("/groups/students/check_transfer", {
          student_id,
          old_group_id,
          new_group_id,
        })
        .then((res) => {
          ctx.state.checkTransferData = res.data;
        })
        .catch(() => []);
    },
    async searchGroup({ commit }, { branch_id, name }) {
      try {
        const res = await apiV2.get("/groups/search", {
          params: { branch_id, name },
        });
        const groups = res.data;
        commit("SET_SEARCHED_GROUPS", groups);
      } catch (error) {
        console.error("Error fetching groups:", error);
        commit("SET_SEARCHED_GROUPS", []);
      }
    },
    clearIsShowCols(ctx) {
      ctx.state.isShowCols = {};
    },
  },
};
