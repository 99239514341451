<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/statistics" class="sidebar__link">
      <img src="@/assets/img/statsitics.png" alt="" />
      <p>{{ $t('Sidebar.statistics') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/archive" class="sidebar__link">
      <img src="@/assets/img/archive.png" alt="" />
      <p>{{ $t('Sidebar.archive') }}</p>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
