<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/kurator-feedbacks" class="sidebar__link">
      <img src="@/assets/img/feedback-white.png" alt=""/>
      <p>{{ $t('Sidebar.feedbacks') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/kurator-archive" class="sidebar__link">
      <img src="@/assets/img/archive.png" alt=""/>
      <p>{{ $t('Sidebar.archive') }}</p>
    </router-link>
  </div>
</template>