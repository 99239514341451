import api from "@/apiConfig/api"
import apiV2 from "@/apiConfig/api-v2"

export const getStudents = async (name, branch_id) => {
    return await api.get("/student/search", {
        params: {
            name,
            branch_id
        }
    })
}

export const getMentors = async (role, name, branch_id) => {
    return await api.get('/users', {
        params: {
            role,
            name,
            branch_id
        }
    })
}

export const getGroups = async (branch_id, name ) => {
    return await apiV2.get("/groups/search", {
        params: {
            branch_id,
            name
        }
    })
}

export const getPhone = async (branch_id, phone) => {
    return await api.get("student/search", {
        params: {
            branch_id,
            phone
        }
    })
}