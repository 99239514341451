export const langMixin = {
  data() {
    return {
        lang: localStorage.getItem("lang") || this.$i18n.locale,
    };
  },
  methods: {
      changeLange (lang) {
        this.$i18n.locale = lang;
        localStorage.setItem("lang", lang);
        this.lang = localStorage.getItem("lang");
        // this.$router.go(this.$router.currentRoute)
        // location.reload();
      }
  }
};
// export default function useLang() {
//   const lang = localStorage.getItem("lang") || this.$i18n.locale;

//   const changeLange = (lang) => {
//     this.$i18n.locale = lang;
//     localStorage.setItem("lang", lang);
//     lang = localStorage.getItem("lang");
//     // this.$router.go(this.$router.currentRoute)
//     location.reload();
//   };

//   return { changeLange, lang };
// }
