import api from "@/apiConfig/api";

export default {
  state() {
    return {
      coursesList: [],
    };
  },
  actions: {
    async getCoursesList(ctx) {
      await api.get("/courses/all").then((res) => {
        ctx.state.coursesList = res.data;
      });
    },
  },
};
