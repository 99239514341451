let str = "";
const reference = "devs";

const Devs = function (position, name, surname) {
  return {
    position,
    name,
    surname,
  };
};

const monitoringe = new Devs("Our guru", "Ruslan", "Yuldashev");
const Nozir = new Devs("Main backend", "Nozir", "Tukhtamurodov");
const IslomA = new Devs("Backend", "Islom", "Abdullaev");
const Jamshid = new Devs("Backend", "Jamshid", "Mahmudjonov");
const farrux = new Devs("Main frontend", "Farrux", "Adxamov");
const IslomSh = new Devs("Frontend", "Islom", "Shahobiddinov");
const Feruza = new Devs("Frontend", "Feruza", "Kayumova");

export const getDevs = document.addEventListener("keypress", (event) => {
  str += event.key;
  if (reference.indexOf(str) !== 0) str = "";
  if (str === reference) {
    console.clear();
    console.table([monitoringe, Nozir, farrux, Jamshid, IslomA, IslomSh, Feruza]);
  }
});
