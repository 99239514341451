import apiV2 from "@/apiConfig/api-v2";

export default {
  state() {
    return {
      certificatesList: [],
      stdCertificatesList: [],
    };
  },
  actions: {
    async getCertificatesList(ctx) {
      ctx.state.certificatesList = await apiV2
        .get("/certificates/template")
        .then((res) => res.data)
        .catch(() => []);
    },
    async getStdCertificatesList(ctx, student_id) {
      ctx.state.stdCertificatesList = await apiV2
        .get("/certificates/student", {
          params: { student_id },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
  },
};
