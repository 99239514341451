<template>
  <div class="content flex-box container">
    <div class="projects component-wrapper">
      <div class="component-header">
        <h2 class="component-heading">{{ group_name }}</h2>
        <button class="component-header__btn" @click="groupUpdate()">
          {{ $t("Projects.refresh") }}
        </button>
      </div>
      <div class="project-body">
        <el-row :gutter="20" style="width: 100%">
          <el-col :xs="24" :md="6">
            <div class="projects-left">
              <div class="projects-left-header">
                <p>{{ $t("Projects.projects") }}</p>
              </div>
              <div class="projects-left-body" v-if="courses?.length > 0">
                <div
                  class="projects-left-body-element"
                  v-for="(theory, index) in slicedArr"
                  :key="theory.id"
                  :class="active_course_id == theory.id ? 'active_theory ' : ''"
                  @click="changeTheory(theory.id)"
                >
                  <p>
                    {{ index + 1 }}. {{ theory?.course_element_uz }}
                    <span class="projects-left-body-element-uploaded">
                      {{ theory?.projects.total_uploaded || 0 }}
                    </span>
                  </p>
                </div>
                <the-pagination
                  :elementsPerPage="11"
                  :totalElements="courses?.length"
                  :elements="courses"
                  @changeActivePage="pageChanged($event)"
                />
              </div>

              <div v-else-if="courses?.length == 0">
                <h4>{{ $t("Projects.data-not-available") }}</h4>
              </div>
              <div v-else-if="!courses">
                <el-skeleton :rows="5" animated />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :md="18">
            <div
              class="student-task-wrapper"
              :class="isFixedStudentsTable && 'fixedStudentsTable'"
              v-if="active_course_answers?.students?.length > 0"
            >
              <methodology-component
                :methodology="homeworkDetails?.methodologies"
                :title="homeworkDetails?.title"
              />
              <HomeworkComponent :course="homeworkDetails" />
              <h3 class="active-course-title">
                {{ selectedCourseElement?.course_element_uz }}
              </h3>
              <MainTable :theadTitles="theadTitles">
                <tr
                  v-for="(std, i) in active_course_answers.students"
                  :key="std.id"
                >
                  <td>{{ i + 1 }}.{{ std.first_name }} {{ std.last_name }}</td>
                  <td>
                    <a
                      class="underline hover:text-orangeThin"
                      :href="medialink + std?.answer?.project_answer?.file"
                      target="_blank"
                      download
                      v-if="std?.answer?.project_answer?.file"
                      >File</a
                    >
                    <span v-else>-</span>
                  </td>
                  <td>
                    <a
                      class="underline hover:text-orangeThin"
                      :href="std?.answer?.project_answer?.link"
                      target="_blank"
                      v-if="std?.answer?.project_answer?.link"
                      >Link</a
                    >
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="std?.answer?.status">{{
                      std?.answer?.status
                    }}</span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="std?.answer?.mark">{{
                      std?.answer?.mark
                    }}</span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="std?.answer?.feedback">{{
                      std?.answer?.feedback
                    }}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
              </MainTable>
            </div>

            <div v-else-if="courses?.length > 0">
              <el-skeleton
                :rows="3"
                animated
                style="width: 100%; height: 32px"
              /></div
          ></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { groupUpdate } from "./api/group-update";
import { getMarks, getDetails } from "./api/get-projects.api";
import HomeworkComponent from "./components/HomeworkComponent.vue";
import ThePagination from "@/components/ThePagination.vue";
import MethodologyComponent from "./components/MethodologyComponent.vue";
import MainTable from "@/components/MainTable.vue";
import { medialink } from "@/apiConfig/medialink";
export default {
  components: {
    HomeworkComponent,
    ThePagination,
    MethodologyComponent,
    MainTable,
  },
  data() {
    return {
      active_course_id: null,
      courses: null,
      group: [],
      group_id: localStorage.getItem("group_id"),
      group_name: localStorage.getItem("group_name"),
      mark: [],
      details: null,
      student_id: null,
      isFixedStudentsTable: false,
      leftX: 689,
      active_course_answers: null,
      student_project: {},
      student_mark: [],
      external_id: localStorage.getItem("external_id"),
      selectedCourseElement: null,
      homeworkDetails: null,
      slicedArr: [],
      theadTitles: ["Student", "File", "Link", "Status", "Baho", "Comment"],
      medialink: medialink,
    };
  },

  methods: {
    async groupUpdate() {
      await groupUpdate(this.external_id);
    },

    async onCollapse() {
      await this.getStudentMarks();
    },
    async changeTheory(course_id) {
      this.student_mark = [];
      this.active_course_id = course_id;
      this.element_id = course_id;
      this.selectedCourseElement = this.courses.find(
        (theory) => theory.id === this.active_course_id
      );
      localStorage.setItem(
        "teacher-hw-active-course-id",
        this.active_course_id
      );

      this.getStudentMarks();
    },
    async getStudentMarks() {
      if (this.courses?.length > 0)
        this.active_course_answers = await getMarks(
          this.group_id,
          this.active_course_id
        ).then((res) => {
          this.homeworkDetails = res.data.course_element;
          if (res.data.status_code === 400) {
            return "-";
          } else {
            return res.data || "-";
          }
        });
    },
    async getGroupDetails() {
      this.courses = await getDetails(this.group_id)
        .then((res) => res.data)
        .catch(() => {
          return [];
        });
    },
    pageChanged({ changedArr }) {
      this.slicedArr = changedArr;
    },
  },
  async beforeMount() {
    // await this.getCourses();
    this.active_course_id = localStorage.getItem("teacher-hw-active-course-id");
    await this.getGroupDetails();

    this.selectedCourseElement = this.courses.find(
      (theory) => theory.id === this.active_course_id
    );
    // this.getStudents();
    this.getStudentMarks();
  },
  // updated() {
  //   this.getStudentMarks()
  // },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
