<template>
  <component :is="layout + '-layout'" v-if="layout" />

  <ScheduleCollapse v-if="userRole === 'admin' || userRole === 'hunter'" />
</template>

<script>
import mainLayout from "./layouts/mainLayout.vue";
import authLayout from "./layouts/authLayout.vue";
import { computed, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import { changeTitle } from "./utils/changeTitle";
import ScheduleCollapse from "@/components/ScheduleCollapse.vue";
import { decryptText } from "@/utils/cryptFunc";
export default {
  name: "App",

  setup() {
    const route = useRoute();
    const userRole = decryptText(localStorage.getItem("role"));

    onBeforeMount(() => {
      changeTitle(route.path);
    });
    watch(
      () => route.name,
      () => {
        changeTitle(route.name);
      }
    );
    return {
      layout: computed(() => route.meta.layout),
      role: computed(() => route.meta.role),
      userRole,
    };
  },
  components: {
    mainLayout,
    authLayout,
    ScheduleCollapse,
  },
};
</script>

<style>
@font-face {
  font-family: "Gilroy";
  src: url(./assets/fonts/Gilroy/Gilroy-Regular.ttf);
}
* {
  font-family: "Gilroy";
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  margin: 0 auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
