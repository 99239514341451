import api from "@/apiConfig/api";

export default {
  state() {
    return {
      branch_list: [],
      active_branch: null,
      active_branch_external_id: null,
    };
  },
  mutations: {
    setActiveBranchId(state, branch_id) {
      state.active_branch = branch_id;
    },
    setActiveBranchExternalId(state, external_id) {
      state.active_branch_external_id = external_id;
    },
  },
  actions: {
    async getBranches(context) {
      await api.get("/company").then((res) => {
        context.state.branch_list = res.data;
      });
    },
  },
};
