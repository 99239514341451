<template>
  <div class="sidebar__link-wrapper" v-if="employeePage">
    <router-link to="#" class="sidebar__link">
      <img src="@/assets/img/statsitics.png" alt="" />
      <p>{{ $t("Sidebar.employee") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="groupPage">
    <router-link to="#" class="sidebar__link">
      <img src="@/assets/img/archive.png" alt="" />
      <p>{{ $t("Sidebar.group") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/finance-branches" class="sidebar__link">
      <img src="@/assets/img/school-sidebar.png" alt="" />
      <p>{{ $t("Admin.branches") }}</p>
    </router-link>
  </div>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/finance-settings" class="sidebar__link">
      <img src="@/assets/img/settings-sidebar.png" alt="" />
      <p>Obunalar</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/finance-mentors" class="sidebar__link">
      <img src="@/assets/img/mentors-icon.png" alt="" />
      <p>Mentors</p>
    </router-link>
  </div> -->
</template>

<script>
import { onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();

    const employeePage = ref(false);
    const groupPage = ref(false);

    const checkRoute = (val) => {
      if (val.name.includes("employee")) {
        employeePage.value = true;
        groupPage.value = false;
      } else if (val.name.includes("salary")) {
        groupPage.value = true;
        employeePage.value = false;
      } else {
        groupPage.value = false;
        employeePage.value = false;
      }
    };

    onBeforeMount(() => {
      checkRoute(route);
    });

    watch(route, (val) => {
      checkRoute(val);
    });

    return {
      employeePage,
      groupPage,
    };
  },
};
</script>

<style></style>
