import apiV2 from "@/apiConfig/api-v2";

export default {
  state() {
    return {
      orders: [],
      debtSum: [],
      financeActiveBranchId: null,
      sbsByCourse: [],
      pendingRefunds: [],
      branchApprovedRefunds: [],
    };
  },
  actions: {
    async getFinanceOrders(ctx, { from_date, till_date }) {
      ctx.state.orders = await apiV2
        .get("/orders", {
          params: {
            from_date,
            till_date,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [];
        });
    },
    async getFinanceDebtSum(ctx) {
      ctx.state.debtSum = await apiV2
        .get("/orders/debt/sum")
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [];
        });
    },
    setFinanceActiveBranchId(ctx, branchId) {
      ctx.state.financeActiveBranchId = branchId;
    },
    async getSbsByCourse(ctx, course_id) {
      ctx.state.sbsByCourse = await apiV2
        .get(`/subscription/${course_id}`, {
          params: {
            course_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getPendingRefunds(ctx, sort) {
      ctx.state.pendingRefunds = await apiV2
        .get("/refund", {
          params: {
            status: "pending",
            sort: sort || "desc",
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getBranchApprovedRefunds(ctx, { branch_id, status }) {
      ctx.state.branchApprovedRefunds = await apiV2
        .get("/refund", {
          params: {
            status: status,
            branch_id: branch_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
  },
};
