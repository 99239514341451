<template>
  <div class="header-search" v-if="headerSearch===true">
    <input
      v-model="searchQuery"
      type="text"
      placeholder="Search"
      class="header-search-input"
      @input="searchHeader"
      :style="{'width': hasSelect ? '560px' : '470px'}"
    />
    <el-select
        v-model="timeSelector"
        placeholder="Select hours"
        size="large"
        v-if="hasSelect"
      >
        <el-option
          v-for="time in lessonsTime"
          :key="time"
          :label="time.label"
          :value="time.id"
        />
      </el-select>
    <button class="header-search-btn">
      <img
        src="@/assets/img/times-circle.png"
        alt=""
        style="width: 14px"
        class="header-search-img"
        @click="clear"
      />
    </button>
    <button class="header-search-btn" @click="headerSearch">
      {{ $t("Adminstration.search") }}
    </button>
  </div>
  <div class="search-bar" v-else>
    <div class="search-bar__input-wrapper">
      <input
        :placeholder="inputPlaceholder"
        type="search"
        :class="[
          'search-bar__input',
          {
            'search-bar__input--long': !selectData,
          },
        ]"
        v-model="searchValue"
      />

      <el-select
        v-model="selectValue"
        class="search-bar__select"
        :placeholder="selectPlaceholder"
        size="large"
        v-if="selectData"
      >
        <el-option
          v-for="data in selectData"
          :key="data"
          :label="data.name"
          :value="data.id"
        />
      </el-select>
    </div>
    <button class="search-bar__btn">{{ $t("search") }}</button>
  </div>
</template>

<script>
import { ref, watch } from "vue";
export default {
  props: [
    "selectData",
    "inputPlaceholder",
    "selectPlaceholder",
    "headerSearch",
    "hasSelect",
    "lessonsTime"
  ],
  emits: ["searchValue", "selectValue"],
  setup(props, { emit }) {
    const searchValue = ref("");
    const selectValue = ref(null);
    const searchQuery = ref("");
    const timeSelector = ref(null);

    if(props.hasSelect) {
      timeSelector.value = props.lessonsTime[0]?.id
    }

    watch(searchValue, (val) => {
      emit("searchValue", {
        searchValue: val,
      });
    });

    watch(selectValue, (val) => {
      emit("selectValue", {
        selectValue: val,
      });
    });

    watch(timeSelector, (val) => {
      emit("selectValue", {
         val,
      });
    });

    const searchHeader = () => {
      if(searchQuery.value.length > 1 || searchQuery.value.length <= 0) {
        emit("search", searchQuery.value);
      }
    };

    const clear = () => {
      searchQuery.value = "";
      emit("clear")
    };

    return {
      searchValue,
      selectValue,
      searchQuery,
      searchHeader,
      clear,
      timeSelector
    };
  },
};
</script>

<style></style>
