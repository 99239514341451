<template>
  <div class="signin" style="padding: 0">
    <div class="header-body">
      <div class="header-body-form-wrapper">
        <el-row>
          <el-col :xs="24" :md="12" class="form-box">
            <div>
              <img src="@/assets/img/mars-logo.png" class="mars-logo" alt="" />
              <h2 class="capitalize">
                {{ $t("welcome-back") }}
                <img class="hand-logo" src="@/assets/img/hand.png" alt="" />
              </h2>
            </div>
            <form @submit.prevent="signIn" v-if="!isSuperUser">
              <el-select
                v-model="selectedRole"
                class="m-2"
                placeholder="Select role"
                size="large"
                v-if="testLogin"
                style="margin-top: 14px"
                @change="autofill()"
              >
                <el-option
                  v-for="item in roles"
                  :key="item"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <label for="username">{{ $t("phone-number") }}</label>
              <input
                id="username"
                type="text"
                v-model="inputData.user.phone"
                @input="formatPhoneNumber"
                placeholder="Username"
                :class="error && 'error'"
              />
              <label for="passowrd">{{ $t("password") }}</label>

              <div class="password-box">
                <input
                  :class="['w-100', error && 'error']"
                  id="password"
                  :type="passwordType"
                  v-model="inputData.user.password"
                  placeholder="Password"
                />

                <button
                  class="eye-btn"
                  type="button"
                  @click="passowrdTypeChange"
                >
                  <img
                    v-if="this.passwordType != 'password'"
                    src="@/assets/img/eyeslash.png"
                    alt=""
                  />
                  <img v-else src="@/assets/img/eye.png" alt="" />
                </button>
              </div>
              <p v-if="errorMsg" class="error-message">{{ errorMsg }}</p>

              <button class="signin-btn" :disabled="isInputsFilled || loading">
                <span v-if="!loading">{{ $t("sign-in") }}</span>
                <span v-else>Loading...</span>
              </button>
            </form>
            <el-col :span="12" v-else class="superuser">
              <h3 class="superuser__title">{{ $t("select-role-you-want") }}</h3>
              <div class="superuser-btn-wrapper">
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('audit')"
                >
                  {{ $t("audit") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('admin')"
                >
                  {{ $t("admin") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('teacher')"
                >
                  {{ $t("teacher") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('instructor')"
                >
                  {{ $t("instructor") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('call')"
                >
                  {{ $t("call-center") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('examiner')"
                >
                  {{ $t("examiner") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('hunter')"
                >
                  {{ $t("hunter") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('teacher', true)"
                >
                  {{ $t("academy") }}
                </button>
                <button
                  class="superuser-btn"
                  @click="redirectSuperUser('tutor')"
                >
                  {{ $t("tutor") }}
                </button>
              </div>
            </el-col>
          </el-col>
          <el-col :xs="24" :md="12" class="signin-img">
            <div class="img-wrapper">
              <img class="form-img" src="@/assets/img/austronaut.png" alt="" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { encryptText, decryptText } from "@/utils/cryptFunc";
import { ElMessage } from "element-plus";

export default {
  name: "SignIn",

  data() {
    return {
      passwordType: "password",
      user: decryptText(localStorage.getItem("role")),
      isValidPhoneNumber: false,
      inputData: {
        user: {
          phone: "+998",
          password: "",
        },
      },
      selectedRole: [],
      error: false,
      errorMsg: "",
      testLogin: process.env.VUE_APP_MODAL_LOGIN || false,
      isSuperUser: false,
      roles: this.$store.state.roles.roles,
      loading: false, // Loading state for the sign-in button
    };
  },
  methods: {
    formatPhoneNumber() {
      let cleaned = this.inputData.user.phone.replace(/\D/g, "");
      let formatted = "+998";

      if (cleaned.length > 3) {
        formatted += " " + cleaned.substring(3, 5);
      }
      if (cleaned.length > 5) {
        formatted += " " + cleaned.substring(5, 8);
      }
      if (cleaned.length > 8) {
        formatted += " " + cleaned.substring(8, 10);
      }
      if (cleaned.length > 10) {
        formatted += " " + cleaned.substring(10, 12);
      }

      this.inputData.user.phone = formatted;
    },
    passowrdTypeChange() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    async signIn() {
      const pattern = this.$store.state.phone.phonePattern;

      const phone = this.inputData.user.phone.replace(/ /g, "");
      // this.inputData.user.phone = this.inputData.user.phone.replace(/ /g, "");

      this.isValidPhoneNumber = pattern.test(phone);

      if (this.isValidPhoneNumber) {
        // Set loading to true to display loading state
        this.loading = true;
        try {
          // Perform the sign-in action
          await this.$store.dispatch("auths/login", {
            user: {
              phone: phone.trim(),
              password: this.inputData.user.password.trim(),
            },
          });
          this.isSuperUser = localStorage.getItem("superuser");
        } catch (error) {
          // Handle errors
          this.error = true;
          this.errorMsg = "Nomer yoki parol notog'ri yozilgan";
          this.formatPhoneNumber();
          this.loading = false;
        }
      } else {
        ElMessage({
          message: "Telefon raqam no'tog'ri formatda kirtildi",
          type: "warning",
        });
      }
    },
    autofill() {
      this.inputData.user.phone = this.roles[this.selectedRole].login;
      this.inputData.user.password = this.roles[this.selectedRole].pass;
    },
    redirectSuperUser(role, isManager) {
      this.$router.push({ name: `${role}-main` });
      localStorage.setItem("role", encryptText(role));
      localStorage.setItem("isManager", isManager);
    },
  },
  beforeMount() {
    if (localStorage.getItem("access_token")) {
      this.$router.push({ name: `${this.user}-main` });
    }
  },
  computed: {
    isInputsFilled() {
      return (
        this.inputData.user.phone.trimStart() === "" ||
        this.inputData.user.password.trimStart() === ""
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &-body {
    background-color: #16233c;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &-form-wrapper {
      width: 850px;
      height: 500px;
      background-color: #fff;
      border-radius: 30px;

      .img-wrapper {
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-img {
          width: 95%;
          height: 95%;
          object-fit: contain;
        }
      }

      .form-box {
        width: 70%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;

        .mars-logo {
          width: 146px;
          height: 39px;
          object-fit: contain;
        }

        .hand-logo {
          width: 65px;
          height: 39px;
          object-fit: contain;
        }

        h2 {
          color: #16233c;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border-bottom: 0.5px solid #dadada;
          padding-bottom: 10px;
          width: 100%;
        }

        input {
          width: 100%;
          border: 1px solid #16233c;
          border-radius: 10px;
          padding: 10px;
          background-color: #fff;
        }

        input.error {
          border: 1px solid red;
        }

        label {
          text-align: left;
          display: block;
          width: 100%;
          margin: 10px 0;
        }

        .signin-btn {
          background: linear-gradient(270deg, #e33d0e 0%, #ff7d58 101.34%);
          border-radius: 10px;
          width: 100%;
          margin-top: 80px;
          padding: 10px;
          border: none;
          cursor: pointer;
          color: #fff;
          display: block;
        }

        .password-box {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;

          .eye-btn {
            position: absolute;
            right: 10px;
            background-color: transparent;
            border: none;
            cursor: pointer;

            img {
              width: 20px;
            }
          }
        }

        .error-message {
          color: red;
          margin-top: 10px;
        }

        button[disabled] {
          cursor: not-allowed;
          background: rgba(#ff7d58, 0.5);
        }

        .superuser {
          &__title {
            margin-top: 20px;
            font-size: 22px;
          }

          &-btn-wrapper {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            column-gap: 10px;
            row-gap: 15px;
          }

          &-btn {
            width: 100px;
            height: 50px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .signin {
    &-img {
      display: none;
    }
  }
}
</style>
