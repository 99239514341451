import api from "@/apiConfig/api";
import apiV2 from "@/apiConfig/api-v2";
import { DateTime } from "luxon";

export default {
  state() {
    return {
      userInfo: {},
      userSalary: {},
      editingUser: {},
      userPositions: [],
      slotList: [],
      tutorList: [],
      studentInfo: {},
      studentRefunds: [],
      studentHistory: [],
    };
  },
  actions: {
    async getMe(ctx) {
      ctx.state.userInfo = await api
        .get("/me")
        .then((res) => res.data)
        .catch(() => {});
    },
    async getUserSalary(ctx, { user_id, recalculate }) {
      const from_date = DateTime.now()
        .minus({ months: 1 })
        .startOf("month")
        .toFormat("yyyy-MM-dd");
      const till_date = DateTime.now()
        .minus({ months: 1 })
        .endOf("month")
        .toFormat("yyyy-MM-dd");

      ctx.state.userSalary = await api
        .get("/users/salary", {
          params: {
            user_id,
            from_date,
            till_date,
            recalculate,
          },
        })
        .then((res) => res.data[0])
        .catch(() => []);
    },
    setEditingUser(ctx, user) {
      ctx.state.editingUser = user;
    },
    async getUserPositions(ctx) {
      ctx.state.userPositions = await api
        .get("users/position_tags")
        .then((res) => res.data);
    },
    async getSlots(ctx) {
      ctx.state.slotList = await api
        .get("users/slots_list")
        .then((res) => res.data);
    },
    async getTutors(ctx, branch_id = null) {
      ctx.state.tutorList = await api
        .get("/users", {
          params: {
            role: "tutor",
            branch_id: branch_id || null,
          },
        })
        .then((res) => res.data);
    },
    async getStdInfo(ctx, student_id) {
      ctx.state.studentInfo = await api
        .get(`/student/${student_id}`, {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => {});
    },
    async archiveStd(ctx, { group_id, student_id }) {
      await api.put(`/groups/${group_id}/students/${student_id}/${6}`, {
        group_id,
        student_id,
        status: 6,
      });
    },
    async getStudentRefunds(ctx, student_id) {
      ctx.state.studentRefunds = await apiV2
        .get("/refund", {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
    async getStudentHistory(ctx, student_id) {
      ctx.state.studentHistory = await apiV2
        .get(`/student/${student_id}/history`, {
          params: {
            student_id,
          },
        })
        .then((res) => res.data)
        .catch(() => []);
    },
  },
};
