import api from "@/apiConfig/api";

export default {
  state() {
    return {
      lead_set_list: [],
      set_students: {},
    };
  },

  actions: {
    async getLeadSets(context, branch_id) {
      await api
        .get(`/leads/set/${branch_id}`, {
          branch_id,
        })
        .then((res) => {
          context.state.lead_set_list = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getSetStudents(context, set_id) {
      
      context.state.set_students[set_id] = [];
      await api
        .get(`/leads/set/${set_id}/leads`, {
          set_id,
        })
        .then((res) => {
          context.state.set_students[set_id] = res.data;
        });
    },
  },
};
