<template>
  <div class="sidebar__link-wrapper" v-if="!isShowRoutes">
    <router-link to="/instructor-groups" class="sidebar__link">
      <img src="@/assets/img/skills-logo.png" alt="" />
      <p>{{ $t('Sidebar.groups') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="isShowRoutes">
    <router-link class="sidebar__link" :to="'/group-coins/' + group_id">
      <img src="@/assets/img/coins-logo.png" alt="" />
      <p>{{ $t('Sidebar.coins') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="isShowRoutes">
    <router-link class="sidebar__link" :to="'/group-projects/' + group_id">
      <img src="@/assets/img/projects-logo.png" alt="" />
      <p>{{ $t('Sidebar.projects') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="isShowRoutes">
    <router-link class="sidebar__link" to="/group-methodology">
      <img src="@/assets/img/methodology-logo.png" alt="" />
      <p>{{ $t('Sidebar.methodology') }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper" v-if="isShowRoutes">
    <router-link class="sidebar__link" :to="'/group-attendance/' + group_id">
      <img src="@/assets/img/attendance-route-icon.png" alt="" />
      <p>{{ $t('Sidebar.attendance') }}</p>
    </router-link>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref, watch, onBeforeMount } from "vue";
export default {
  setup() {
    const isShowRoutes = ref(false);
    const route = useRoute();
    const group_id = ref(localStorage.getItem("group_id"));

    watch(route, () => {
      isShowRoutes.value =
        route.name !== "instructor-main" && route.name !== "instructor-groups"
          ? true
          : false;

      group_id.value = localStorage.getItem("group_id");
    });
    onBeforeMount(() => {
      isShowRoutes.value =
        route.name !== "instructor-main" && route.name !== "instructor-groups"
          ? true
          : false;
    });
    return {
      group_id,
      isShowRoutes,
    };
  },
};
</script>

<style></style>
